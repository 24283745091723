exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gmail-apps-privacy-policy-js": () => import("./../../../src/pages/gmail-apps-privacy-policy.js" /* webpackChunkName: "component---src-pages-gmail-apps-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-crm-js": () => import("./../../../src/pages/products/crm.js" /* webpackChunkName: "component---src-pages-products-crm-js" */),
  "component---src-pages-products-hrms-js": () => import("./../../../src/pages/products/hrms.js" /* webpackChunkName: "component---src-pages-products-hrms-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

